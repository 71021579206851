import request from '../utils/request'
import baseUrl from './baseUrl'

export const roomLogList = (data) => request({
  url: baseUrl + '/roomLog/selectForBack',
  method: 'POST',
  data
})
export const roomLogDetails = (data) => request({
  url: baseUrl + '/roomLog/selectForId',
  method: 'POST',
  data
})