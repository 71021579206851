<template>
  <div class="roomLog">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加客房</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>修改类目:</span>
      <el-select
        @clear="onChange"
        v-model="list.logType"
        placeholder="请选择"
        clearable
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getRoomLog()">查找</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column align="center" prop="hotelLogId" label="日志ID">
      </el-table-column>
      <el-table-column align="center" prop="hotelAddTime" label="修改时间">
      </el-table-column>
      <el-table-column align="center" prop="strType" label="修改类型">
      </el-table-column>
      <el-table-column align="center" prop="masterName" label="修改人">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/roomLogDetails/${row.hotelLogId}`)"
            >查看日志详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { roomLogList } from "../../api/roomLog";
export default {
  name: "roomLog",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      pagination: {},
      list: {
        currentPage: 1,
        pageSize: 5,
        roomId: Number(this.id),
        logType: null,
      },
      options: [
        {
          value: 1,
          label: "新增",
        },
        {
          value: 2,
          label: "上/下架",
        },
        {
          value: 3,
          label: "名称",
        },
        {
          value: 4,
          label: "所属酒店",
        },
        {
          value: 5,
          label: "轮播图",
        },
        {
          value: 6,
          label: "展示图",
        },
        {
          value: 7,
          label: "床型",
        },
        {
          value: 8,
          label: "面积",
        },
        {
          value: 9,
          label: "楼层",
        },
        {
          value: 10,
          label: "入住人数",
        },
        {
          value: 11,
          label: "设施设备",
        },
        {
          value: 12,
          label: "简介",
        },
        {
          value: 13,
          label: "费用 明细",
        },
        {
          value: 14,
          label: "排序",
        },
        {
          value: 15,
          label: "退订",
        },
        {
          value: 16,
          label: "支付方式",
        },
        {
          value: 17,
          label: "库存/价格",
        },
      ],
    };
  },
  created() {
    this.getRoomLog();
  },
  methods: {
    async getRoomLog() {
      const { data } = await roomLogList(this.list);
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onChange() {
      this.list.logType = null;
    },
    handleSizeChange(size) {
      this.list.size = size;
      this.getRoomLog();
    },
    handleCurrentChange(current) {
      this.list.currentPage = current;
      this.getRoomLog();
    },
  },
};
</script>
<style lang="less" scoped>
.roomLog {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-select {
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>